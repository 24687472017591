import { makeStyles } from "@material-ui/core";

export const useHeaderStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    zIndex: 10,
    alignItems: "center",
  },
  logo: {
    marginRight: "5%",
    alignSelf: "flex-start",
    height: "100%",
    width: "auto",
  },
}));
