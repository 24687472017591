import clsx from "clsx";
import { Link } from "gatsby-material-ui-components/lib/link";
import React from "react";
import { NvonLogo } from "../../../__generated__/svgComponents/NvonLogo";
import { useHeaderStyles } from "./headerStyles";

export interface HeaderProperties {
  siteTitle?: string;
  className?: string;
}

const Header = (props: HeaderProperties): JSX.Element => {
  const classes = useHeaderStyles();
  return (
    <header className={clsx(props.className, classes.wrapper)}>
      <Link
        to="https://nvon.com/"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
        title="Back to home page"
        className={classes.logo}
      >
        <NvonLogo className={classes.logo} />
        {props.siteTitle && <h1 style={{ margin: 0 }}>{props.siteTitle}</h1>}
      </Link>
      <Link
        to="/"
        style={{
          marginRight: "1rem",
        }}
        title="Sections"
      >
        Sections
      </Link>
      <Link
        to="/controls/"
        style={{
          textDecoration: `none`,
        }}
        title="Controls"
      >
        Controls
      </Link>
    </header>
  );
};

export default Header;
