import React from "react";
import Header from "../../components/03-organisms/header/Header";
import { PageLayoutProps, usePageLayoutStyles } from "./pageLayoutStyles";

export const PageLayout: React.FunctionComponent<PageLayoutProps> = (
  props: PageLayoutProps
) => {
  const classes = usePageLayoutStyles(props);
  return (
    <div className={classes.layoutWrap}>
      <Header className={classes.header} />
      <main className={classes.main}>{props.children}</main>
    </div>
  );
};
