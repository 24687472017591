import { makeStyles, Theme } from "@material-ui/core";
import { ReactNode } from "react";

export interface PageLayoutProps {
  children: ReactNode;
  centerContent?: true;
}

export const usePageLayoutStyles = makeStyles<Theme, PageLayoutProps>(
  (theme) => {
    const padding = theme.spacing(2);
    return {
      layoutWrap: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        position: "relative",
        overflowX: "hidden",
      },
      header: {
        padding,
        height: "80px",
        backgroundColor: theme.palette.grey[50],
      },
      main: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: (props) =>
          props.centerContent ? "center" : "flex-start",
        alignItems: (props) => (props.centerContent ? "center" : "flex-start"),
      },
    };
  }
);
